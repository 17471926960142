import moment from 'moment-timezone';
import 'moment/locale/de'  // without this line it didn't work

import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client'
import * as prismic from '@prismicio/client'

const repoName = 'sifi-eats-v2'; // Fill in your repository name.
// const accessToken = ''; // If your repo is private, add an access token.
// const endpoint = prismic.getEndpoint(repoName); // Format your endpoint.

const prismicClient = prismic.createClient(
  prismic.getEndpoint(repoName),{fetch}
)

const client = new ApolloClient({
  link: new HttpLink({
    uri: prismic.getGraphQLEndpoint(repoName),
    fetch: prismicClient.graphqlFetch,
    useGETForQueries: true,
  }),
  cache: new InMemoryCache(),
})

export async function callHook({uid, url, params={}}) {
  let response = null;
  try {
    const options = {
      method: 'POST',
      mode: "cors", // no-cors, *cors, same-origin
      redirect: 'follow',
      headers: { "content-type": "application/json" },
    };
    //console.log(`send to: ${url}`);

    // const data = JSON.stringify(params);

    options.body = JSON.stringify({uid, data:params});
    response = await fetch(url, options);
    // console.log(response);
    //console.log('status code: ', response.status); // 👉️ 200

    if (!response.ok) {
      //console.log(response);
      throw new Error(`Error! status: ${response.status}`);
    }
    const json = await response.json();
    return json;
  } catch (e) {
    // interface Error {
    //   name: string;
    //   message: string;
    //   stack?: string;
    // }
    const status = response ? response.status : null;
    //console.log(e);
    const message = e.message || 'Error';
    const r = {success: false, message, status}
    return r;
  }
}

export async function loadEvents(limit) {
  // const _rests = await client.getByUID('restaurant','restaurants')
  const event = await client.query({
    query: gql`query MyQuery {
      allEvents(sortBy: start_time_DESC, first:${limit}, where:{restaraunt_link: "YiX8wREAACgAH1oD"}) {
        edges {
          node{
            body{
              __typename
              ...on EventBodyEvent_info {
                type
                label
                primary {
                  description
                  # phone
                  # email
                  # address
                  # coordinates
                  # facebook_url {
                  #   __typename
                  # }
                }
                fields {
                  image
                }
              }
            }
            _meta{
              uid
            }
            title
            start_time
            finish_time
          }
        }
      }
    }`
  })
  const now = moment().utc()
  const {data, loading} = event;
  const _events = data?.allEvents?.edges?.map(item => {
    // console.log('node: ', item.node);
    const body = item.node.body.map(item => {
      // console.log('node body: ', item);
      const _item = {
        slice_type: item.type,
        items: item.fields,
        primary: item.primary
      };
      return _item;
    });
    const {title, start_time, finish_time} = item.node;
    const finishUtc = moment(finish_time).tz("Europe/Berlin")
    const isOver = now.isAfter(finishUtc)
    const _data = {
      body,
      title: title[0],
      start_time,
      startTime: moment(start_time).unix(),
      finish_time,
      isOver
    };
    const uid = item.node._meta.uid;
    // const url = `https://www.sifi-eats.de/events/${uid}`;
    const url = `${window.location.origin}/events/${uid}`;
    return {data: _data, uid, url};
  })
  // .sort((a,b) => {
  //   return b.data.startTime - a.data.startTime;
  // });
  // console.log('event: ', _events);
  const over_1 = _events.filter(item => {
    return item.data.isOver;
  });
  const over_2 = _events.filter(item => {
    return !item.data.isOver;
  });
  const filtered = [...over_2, ...over_1];
  return filtered;
}